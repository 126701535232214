﻿.logo {
	position: absolute;
	top: 21px;
	left: 20px;
	z-index: 1;

	@media #{$laptop} {
		width: 322px;
		height: 71px;

		.headroom--pinned.headroom--not-top & {
			width: 200px;
			height: 30px;
		}
	}

	a {
		display: inline-block;
		text-indent: -9999px;
		background: url(../../images/holstein-schoonmaakdiensten-logo.png) no-repeat;
		background-image: url(../../images/holstein-schoonmaakdiensten-logo.svg), none;
		background-size: 100% auto;
		overflow: hidden;
		width: 280px;
		height: 62px;

		@media #{$mobile-large} {
			width: 300px;
			height: 45px;
		}

		@media #{$laptop} {
			width: 322px;
			height: 71px;

			.headroom--pinned.headroom--not-top & {
				width: 200px;
				height: 30px;
			}
		}
	}
}
