﻿$font-text: Arial, "Helvetica", sans-serif;
$font-title: "GeometricSlab", "Helvetica Neue", $font-text;

$color-text: #333333;
$color-primary: #0046ad;
$color-primary-dark: #002776;
$color-primary-contrast: #1a3d84;
$color-secondary: #00a1de;
$color-secondary-light: #a0cfeb;
$color-contrast: #fafbfc;
$color-highlight: #60ba19;
$color-highlight-dark: #417913;
$color-border: #e6e6e6;
$color-white: #ffffff;

