﻿.slider {
	position: relative;
	margin: 4px 0 24px 0;
	width: 100%;
	list-style: none;

	li:nth-child(n + 2) {
		display: none;
	}

	&.lSSlideOuter {

		li:nth-child(n + 2) {
			display: block;
		}

		.slider {
			float: none;
			margin: 0;
		}


		.lSPager.lSpg {
			position: absolute;
			width: 100%;
			bottom: 5px;

			li {
				display: inline-block !important;

				> a {
					width: 10px;
					height: 10px;
					border: 2px solid $color-white;
					background: none;
					box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.9);
				}

				&:hover a {
					background: $color-white;
				}

				&.active a {
					background: $color-white;
				}
			}
		}
	}

	@media #{$tablet-small} {
		float: right;
		width: 303px;
		margin: 4px 0 24px 40px;
	}

	@media #{$tablet} {
		float: none;
		width: 100%;
		margin: 4px 0 24px 0;
	}

	@media #{$laptop} {
		float: right;
		width: 303px;
		margin: 4px 0 24px 40px;
	}

	@media #{$laptop-med} {
		width: 404px;
	}
}
