﻿.header-blocks {
	margin-top: 17px;

	@media #{$tablet} {
		margin-top: 117px;
		display: flex;
		align-items: center;
	}
}

.header-block {
	font: 18px/24px $font-text;
	color: $color-white;
	padding: 20px 0;

	p {
		margin: 0 0 29px;
	}
}

.header-block__large-header {
	font: bold 32px/32px $font-title;
	color: $color-white;
	margin: 0 0 20px;
	text-shadow: 2px 2px rgba(0,0,0,.7);

	+ p {
		text-shadow: 2px 2px rgba(0,0,0,.7);
	}

	p {
		margin: 0;
	}

	span {
		color: $color-primary;
	}

	@media #{$tablet-small} {
		font-size: 40px;
		line-height: 40px;
	}

	@media #{$tablet} {
		font-size: 48px;
		line-height: 48px;
		text-shadow: none;

		+ p {
			text-shadow: none;
		}
	}
}

.header-block__block {
	background: rgba($color-white, 0.9);
	color: $color-text;
	font: 16px/24px $font-text;
	padding: 26px 35px 20px;
	overflow: hidden;
	border-radius: 5px 5px 0 0;


	strong {
		color: $color-primary;
	}
}

.header-block__header {
	font: bold 24px/24px $font-title;
	color: $color-primary;
}

.header-block__button {
	display: block;
	border-radius: 0;

	&:last-child {
		border-radius: 0 0 5px 5px;
	}
}

.header-block__buttons {

	.button--primary + .button--primary {
		padding-right: 85px;

		span {
			position: relative;
			display: block;

			&:after {
				position: absolute;
				content: '';
				width: 100%;
				height: 1px;
				top: -12px;
				left: 0;
				background: rgba($color-white, 0.5);
			}
		}
	}
}