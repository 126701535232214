﻿// GeometricSlab

@font-face {
    font-family: 'GeometricSlab';
    src: url('../fonts/GeometricSlab-Bold.eot');
    src: url('../fonts/GeometricSlab-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/GeometricSlab-Bold.woff2') format('woff2'), url('../fonts/GeometricSlab-Bold.woff') format('woff'), url('../fonts/GeometricSlab-Bold.svg#GeometricSlab-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-02DC;
}

@font-face {
    font-family: 'GeometricSlab';
    src: url('../fonts/GeometricSlab-BoldItalic.eot');
    src: url('../fonts/GeometricSlab-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/GeometricSlab-BoldItalic.woff2') format('woff2'), url('../fonts/GeometricSlab-BoldItalic.woff') format('woff'), url('../fonts/GeometricSlab-BoldItalic.svg#GeometricSlab-BoldItalic') format('svg');
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-02DC;
}

@font-face {
    font-family: 'GeometricSlab';
    src: url('../fonts/GeometricSlab-Medium.eot');
    src: url('../fonts/GeometricSlab-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/GeometricSlab-Medium.woff2') format('woff2'), url('../fonts/GeometricSlab-Medium.woff') format('woff'), url('../fonts/GeometricSlab-Medium.svg#GeometricSlab-Medium') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-02DC;
}

@font-face {
    font-family: 'GeometricSlab';
    src: url('../fonts/GeometricSlab-MediumItalic.eot');
    src: url('../fonts/GeometricSlab-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/GeometricSlab-MediumItalic.woff2') format('woff2'), url('../fonts/GeometricSlab-MediumItalic.woff') format('woff'), url('../fonts/GeometricSlab-MediumItalic.svg#GeometricSlab-MediumItalic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-02DC;
}