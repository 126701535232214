﻿/*------------------------------------*\
	#Route form
\*------------------------------------*/

.form--route.form {
    padding: 25px 0 19px;
    margin: 0;

    form {
        @media #{$tablet} {
            display: flex;
            //max-width: 640px;
            margin: 0 auto;
			padding: 0 20px;
        }

        input[type=text] {
            width: 100% !important;
            margin-bottom: 20px;

            @media #{$tablet} {
                margin-bottom: auto;
                margin-right: 20px;
            }
        }
    }
}

.form--route__button {
	width: 100%;
	background: none;
	border: none;
	margin: 7px 0 8px;

	 @media #{$tablet} {
		flex: 0 0 auto;
		width: auto;
	}
}