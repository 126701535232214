﻿section {
	position: relative;
	padding: 50px 0 36px;
	border-bottom: 1px solid $color-border;
	@include clearfix();

	&:before {
		box-sizing: border-box;
		position: absolute;
		content: "";
		width: 1280px;
		height: 100%;
		top: 1px;
		left: 100%;
		background: #fff;
		border-bottom: 1px solid $color-border;
	}

	&:nth-of-type(odd) {
		background: $color-contrast;

		&:before {
			background: $color-contrast;
		}
	}

	&:last-child {
		border-bottom: none;

		&:first-child {

			&:before {
				border-bottom: none;
			}
		}
	}

	&.no-padding {
		padding: 0;
	}
}

	.section__padding {
		padding: 50px 0 36px;
	}

	.section__paddingt-top {
		padding: 50px 0 0;
	}

	.section__padding-bottom {
		padding: 0 0 36px;
	}

	.frontpage section:nth-child(odd) {

		@media #{$laptop} {
			.column {
				float: right;
			}

			.column + .column {
				float: left;
			}
		}
	}
