﻿.validation-summary-errors {
	background: mix(red, #fff, 20%);
	padding: 9px 9px 3px;
	border: 1px solid mix(red, #fff, 40%);
	border-radius: 3px;
	margin: 24px 0 20px;

	p,
	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 8px;

		&:last-child {
			margin: 0 0 8px;
		}
	}
}
