﻿.image {
	margin: 3px 0 24px;
}

.image--right {
	margin: 4px 0 24px 0;
	width: 100%;

	@media #{$tablet-small} {
		float: right;
		width: 303px;
		margin: 4px 0 24px 40px;
	}

	@media #{$tablet} {
		float: none;
		width: 100%;
		margin: 4px 0 24px 0;
	}

	@media #{$laptop} {
		float: right;
		width: 303px;
		margin: 4px 0 24px 40px;
	}

	@media #{$laptop-med} {
		width: 404px;
	}
}

.image--wide {
	margin: 6px 0 24px;
}

.image__full-background {
	@include responsive-container(624, 624);
	width: calc(100% - 40px);
	height: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	a {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	@media #{$tablet-small} {
		padding: 0;
		height: 624px;
	}

	@media #{$laptop} {
		position: absolute;
		padding: 0;
		height: 100%;
		top: 0;
		left: 20px;
	}
}