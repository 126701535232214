﻿input[type=text],
input[type=password],
input[type=email],
input[type=tel],
textarea,
select {
	box-sizing: border-box;
	font: 16px/24px $font-text;
	padding: 4px 16px 3px;
	-webkit-appearance: none;
	outline: none;
	height: 39px;
	border: 1px solid $color-border;

	section:nth-of-type(even) & {
		background: #fff;
	}

	&.ivld {
		background: mix(red, #fff, 20%);
		border-color: mix(red, #fff, 40%);
	}

	&.required {
		border-left: 1px solid mix(red, #fff, 40%);
	}
}

input[type=file] {

	&.required {
		border-left: 2px solid mix(red, #fff, 40%);
	}
}

textarea {
	resize: vertical;
	overflow: auto;
	height: auto;
	min-height: 202px;
}

label {
	font: 16px/24px $font-text;
	padding: 7px 16px 8px 0;
}

select {
	-moz-appearance: none;
	border-radius: 0;
	padding: 8px 16px 9px;
	width: 100%;
	background: $color-white url(../../images/select-background.jpg) no-repeat center right;

	&.ivld {
		background: mix(red, #fff, 20%) url(../../images/select-background.jpg) no-repeat center right;
	}
}

select::-ms-expand {
	display: none;
}

.form-check {
	display: none;
}