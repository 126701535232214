﻿// Arrow
.arrow {
	display: inline-block;
	font: bold 20px/24px $font-title;
	color: $color-primary;
	text-decoration: none;
	margin: 16px 0 18px;
	transition: color 0.2s ease-in-out;
	cursor: pointer;
	background: none;
	border: none;

	&:hover {
		color: $color-secondary;
		text-decoration: none;

		&:after {
			left: 4px;
		}
	}

	&:after {
		position: relative;
		@include icon($icon-arrow-right);
		font-size: 10px;
		left: 2px;
		transition: left 0.2s ease-in-out;
	}
}