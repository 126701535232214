﻿.text {

	ul {
		overflow: hidden;
		border-bottom: 1px solid $color-border;
		list-style: none;

		li {
			position: relative;
			border-top: 1px solid $color-border;
			padding: 11px 0 15px 48px;

			&:before {
				position: absolute;
				@include icon($icon-arrow-right); 
				top: 19px;
				left: 21px;
				font-size: 10px;
			}
		}
	}

	h3 {
		font: bold 22px/24px $font-title;
		color: $color-primary;
		margin-bottom: 16px;
	}
}
