﻿.slogan-bar {
	font: 20px/24px $font-title;
	background: $color-primary-dark;

	.column {
		display: flex;
		min-height: 75px;
		align-items: center;

		@media #{$tablet} {
			min-height: 100px;
		}
	}

	.column--phone {

		@media #{$tablet} {
			justify-content: flex-end;
		}
	}
}

.slogan-bar__slogan {
	margin: 0;
	font: bold 20px/24px $font-title;
	color: $color-white;
}

.slogan-bar__telephone {
	color: $color-white;

	a {
		font-weight: bold;
		color: $color-white;
	}
}