﻿@mixin button-hover() {
	background-color: $color-highlight-dark;
	text-decoration: none;
}

.button {
	position: relative;
	z-index: 1;
	display: inline-block;
	font: 18px/24px $font-title;
	padding: 12px 60px 14px 20px;
	transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.2s ease-in-out;
	-webkit-appearance: none;
	cursor: pointer;
	text-decoration: none;
	background-color: $color-highlight;
	color: $color-white;
	border-radius: 6px;
	border: none;
	overflow: hidden;
	text-align: left;

	&:after {
		position: absolute;
		@include icon($icon-arrow-right);
		width: 50px;
		height: 100%;
		padding: 20px 0 0 22px;
		top: 0;
		right: 0;
		font-size: 10px;
		background-color: $color-highlight-dark;
	}

	&:hover {
		@include button-hover();
	}
}

.button--full-width  {
	width: 100%;
}

@mixin button--primary-hover() {
	background: $color-primary;
}

.button--primary {
	background: $color-secondary;

	&:after {
		background: $color-primary;
	}

	&:hover {
		@include button--primary-hover();
	}
}