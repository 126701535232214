﻿.employee {
	position: relative;
	border: 1px solid $color-border;
	background: $color-contrast;
	margin: 24px 0 0;
}

.employee__intro  {
	padding: 14px 20px 6px;
}

.employee__name {
	font: bold 20px/1.2em $font-title;
}

.employee__job-title {
	font: bold 16px/24px $font-title;
	color: $color-secondary-light;
	margin: -12px 0 0;
}

.employee__button-container {
	position: absolute;
	width: 100%;
	padding: 0 20px;
	bottom: 12px;
	left: 0;
}