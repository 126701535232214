﻿/*------------------------------------*\
	#Toggler
\*------------------------------------*/

.toggler {
    z-index: 101;
    position: fixed;
    width: 320px;
    width: (100% / 3);
    height: 50px;
    right: 0;
    bottom: 0;
    font-size: 14px;
    color: #fff;
    cursor: pointer;

    &:after {
        position: absolute;
        font-size: 14px;
        text-indent: 0;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        @include icon($icon-close); // close
    }

    span {
        position: absolute;
        display: block;
        width: 100%;
        bottom: -1px;
        left: 0;
        font: 15px/26px $font-text;
        text-indent: 0;
        text-align: center;
    }

    @media #{$laptop} {
        display: none;
    }
}

.toggler--closed {
    width: (100% / 3);

    &:after {
        @include icon($icon-menu); // menu
    }
}