﻿.contact-box {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    height: 50px;
    transition: right 250ms ease;
    background: $color-primary;

    @media #{$laptop} {
        width: 320px;
        top: 189px;
        right: -260px;
        bottom: auto;
        height: 130px;
        background: none;
    }
}

.contact-box-item {
    position: relative;
    float: left;
    box-sizing: border-box;
    background: $color-primary;
    font: 16px/30px $font-text;
    color: #fff;
    width: (100% / 3);
    height: 50px;
    transition: right 250ms ease;
    right: 0;

    &:before {
        color: #fff;
        font-size: 14px;
        position: absolute;
        z-index: 1;
        cursor: default;
        top: 6px;
        left: 50%;
        transform: translateX(-50%);

        @media #{$laptop} {
            transform: none;
            font-size: 24px;
        }
    }

    &:after {
        position: absolute;
        top: 0;
        left: -60px;
        content: '';
        width: 60px;
        height: 100%;
        font-size: 36px;
        border-radius: 5px 0 0 5px;
        margin-bottom: 1px;
        background: $color-primary;

        @media #{$laptop} {
            display: block;
        }
    }

    a {
        display: block;
        color: #fff;
        position: relative;
        z-index: 2;
        overflow: hidden;
        width: 100%;
        height: 50px;
        text-indent: -9999px;

        &:hover {
            text-decoration: underline;
        }

        &:after {
            position: absolute;
            content: attr(data-text);
            width: 100%;
            bottom: -1px;
            left: 0;
            font: 15px/26px $font-text;
            text-indent: 0;
            text-align: center;

            @media #{$laptop} {
                display: none;
            }
        }

        @media #{$laptop} {
            display: block;
            position: static;
            width: auto;
            height: auto;
            text-indent: 0;
            font: 400 18px/1.2em $font-title;
        }
    }

    @media #{$laptop} {
        width: 100%;
        height: 56px;
        padding: 18px 20px 12px 30px;
        margin: 0 0 1px 60px;

        &:hover,
        &.contact-box--open {
            right: 260px;
        }
    }
}

// Telephone
.contact-box__item--telephone {

    &:before {
        @include icon($icon-phone);

        @media #{$laptop} {
            top: 14px;
            left: -42px;
        }
    }
}

// Contact
.contact-box__item--email {
    background: $color-secondary;

    &:before {
        @include icon($icon-mail);
        font-size: 20px;

        @media #{$laptop} {
            top: 14px;
            left: -42px;
            font-size: 28px;
        }
    }

    &:after {
        @media #{$laptop} {
            background: $color-secondary;
        }
    }
}