﻿header {
	position: absolute;
	//background: $color-secondary-light;
	width: 100%;
	height: 80px;

	@media #{$laptop} {
		background: linear-gradient(to bottom, rgba(0,39,118,0.25) 0%,rgba(0,0,0,0) 100%);
		z-index: 20;
		height: 100px;
		box-shadow: none;

		&.headroom--pinned.headroom--not-top {
			position: fixed;
			top: 0;
			left: 0;
			background: #fff;
			width: 100%;
			height: 80px;
			box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
			transition: transform 0.3s ease-in-out;
		}
	}
}


.headroom {
    will-change: transform;
    transition: all 0.3s ease-in-out;
}

.headroom--pinned {
    transform: translateY(0%);
}

.headroom--unpinned {
    transform: translateY(-100%);
}