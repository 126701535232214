﻿html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	padding: 0;
	border: none;
	outline: none;
	margin: 0;
}

img {
	vertical-align: bottom;
}

// Always show vertical scrollbar
html {
	overflow-y: scroll;
}

body {
	line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

nav ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}

a {
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	padding: 0;
	margin: 0;
}

// Change colours to suit your needs
ins {
	color: #000;
	background-color: #ff9;
	text-decoration: none;
}

// Change colours to suit your needs
mark {
	color: #000; 
	background-color: #ff9;
	font-style: italic;
	font-weight: bold;
}

del {
	text-decoration: line-through;
}

abbr[title], acronym[title], dfn[title] {
	cursor: help;
	border-bottom: 1px dotted;
}

// Tables still need 'cellspacing="0"' in the markup
table {
	border-collapse: collapse;
	border-spacing: 0;
}

// Change border colour to suit your needs
hr {
	display: block;
	height: 0;
	padding: 0;
	margin: 1em 0;
	border: none;
	border-top: 1px solid #ccc;
}

input, select {
	vertical-align: middle;
}

*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
 }