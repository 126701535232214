﻿.form-contact {
	margin-top: -22px;

	label {

		@media #{$tablet-small} {
			float: left;
			width: 180px;
		}
	}

	.umbraco-forms-field-wrapper {

		@media #{$tablet-small} {
			float: left;
			width: calc(100% - 180px);
		}
	}
}