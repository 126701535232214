﻿.video__wrapper {
	max-width: 1024px;
	margin: 21px 0 24px;
}

.video {
	@include responsive-container(1024, 576);

	iframe {
		position: absolute;
		width:  100%;
		height: 100%;
		top:  0;
		left: 0;
	}
}