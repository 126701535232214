﻿.container,
#container{
	width: 100%;
	max-width: 1324px;
	padding: 0 20px;
	margin: 0 auto;
	@include clearfix();
}

.container--relative {
	position: relative;
	z-index: 2;
}

#container {
	display: flex;
	flex-direction: column;
	padding: 0;

	@media #{$tablet} {
		display: block;
		padding: 0 20px;
	}

	.frontpage & {
		max-width: unset;
		padding: 0;
	}
}