﻿.call-to-action {
	background-size: cover;
	background-position: left center;

	@media #{$mobile-large} {
		background-position: center;
	}

	.container {
		display: flex;
		align-items: center;
		min-height: 380px;
	}
}

.call-to-action__container {
	max-width: 600px;
	color: $color-white;
	font: 400 18px/24px $font-title;
	padding: 21px 0 17px;
}

.call-to-action__title {
	color: $color-white;
	margin: 0 0 16px;
	font: bold 48px/48px $font-title;

	span {
		color: $color-primary;
	}

	p {
		margin: 0;
	}
}