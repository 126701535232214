﻿.to-top {
	display: block;
	position: absolute;
	width: 162px;
	height: 40px;
	top: -39px;
	right: 50%;
	background: url(../../images/to-top.png);
	margin-right: -81px;

	&:after {
		position: absolute;
		font-size: 18px;
		content: '';
		@include icon($icon-arrow-up);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $color-white;
		transition: margin 0.2s ease-in-out;
	}

	&:hover {

		&:after {
			margin-top: -2px;
		}
	}

	@media #{$laptop-large} {
		margin-right: 0;
		right: -73px;
	}

	@media #{$desktop} {
		right: -173px;
	}
}
