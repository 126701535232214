﻿@import "variables";

.lg-backdrop.in {
    &.in {
        opacity: 0.9;
    }
}

#lg-counter {
    color: #fff;
}

.lg-toolbar .lg-icon {
    color: #fff;

    &:hover {
        color: $color-secondary;
    }
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
    background: none;
    color: #fff;

    &:hover {
        color: $color-secondary;
    }
}