﻿.certificate {
	background: $color-white;
}

.certificate__item {
	float: left;
	padding: 18px;
}

.certificate__image {
	width: 120px;

	@media #{$tablet} {
		width: 140px;
	}
}

.certificate__wrapper {
	overflow: hidden;
	margin: 0 -18px;
}

.certificate__link {
	float: left;
	width: 264px;
	height: 180px;
	padding: 74px 0 20px 20px;
	margin: 0;

	/*@media #{$tablet} {
		padding: 74px 0 20px 0;
	}*/
}