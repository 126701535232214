﻿// Breadcrumbs
.breadcrumbs {
	position: relative;
	font: 16px/1.5em $font-title;
	color: #fff;
	padding: 6px 0 25px;
	margin: -3px 0 0;
	font-weight: 700;

	@media #{$laptop} {
		padding-bottom: 5px;
	}

	&,
	ul {
		list-style: none;
	}

	li,
	ul,
	a {
		display: inline-block;
		vertical-align: top;
	}

	li + li {
		position: relative;
		padding-left: 21px;

		&:before {
			position: absolute;
			@include icon($icon-arrow-right);
			font-size: 10px;
			top: 8px;
			left: 3px;
		}
	}

	a {
		position: relative;
		color: #fff;
		text-decoration: none;
		font-weight: 400;

		&:hover {
			text-decoration: underline;
		}
	}
}
