﻿// Contact call
.contact-call {
	position: absolute;
	display: none;
	background: rgba(0, 39, 118, 0.87);
	font: 18px/27px $font-title;
	color: $color-secondary-light;
	width: 100%;
	padding: 22px 0;
	bottom: 0;
	left: 0;
	z-index: 1;

	@media #{$tablet-small} {
		font: 24px/36px $font-title;
	}

	@media #{$tablet-small} {
		display: block;
	}

	span {
		color: $color-white;
		display: block;

		a {
			color: $color-white;
		}

		@media #{$tablet-small} {
			display: inline-block;
		}
	}
}

.contact-call__scroll-down {
	display: block;
	position: absolute;
	width: 150px;
	height: 40px;
	margin: 0 0 0 -75px;
	top: -39px;
	right: 50%;
	background: url(../../images/scroll-down.png);
	transform: translateX(-50);

	&:after {
		position: absolute;
		font-size: 18px;
		@include icon($icon-arrow-down);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $color-white;
		transition: margin 0.2s ease-in-out;
	}

	&:hover {

		&:after {
			margin-top: -2px;
		}
	}
}