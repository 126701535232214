﻿.legal {
	position: relative;
	list-style: none;
	width: 100%;
	clear: both;
	padding: 27px 0 39px;
	margin: 0 auto;
	overflow: hidden;
	font: 12px/16px $font-text;
	color: rgba($color-text, 0.5);

	li {
		display: inline-block;
		margin: 0 8px 0 0;
	}

	a {
		color: rgba($color-text, 0.5);
	}
}

li.panorama-studios {

	a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	@media #{$tablet-small} {
		float: right;
	}
}

.legal-container {
	background: $color-white;
}