﻿.nav {
	list-style: none;
	padding: 0 0 80px;
	margin: 0;

	@media #{$laptop} {
		float: right;
		margin-right: -20px;
		padding: 0 0 0 342px;
	}

	li {
		display: inline-block;
		width: 100%;
		position: relative;

		&.home {
			@media #{$laptop} {
				display: none;
			}
		}

		&.offerte  {
			background: $color-highlight;
			border-radius: 0 0 5px 5px;
		}

		&.sub {

			> a {
				position: relative;
				padding-right: 20px;

				&:after {
					/*@include icon($icon-arrow-down); // arrow down*/
					font-size: 10px;
					margin-left: 6px;
					vertical-align: middle;

					@media #{$laptop} {
						display: none;
					}
				}
			}

			@media #{$laptop} {
				width: auto;

				&:hover {

					a {
						color: $color-text;
						background: $color-white;
						text-decoration: none;
						text-shadow: none;
					}

					&.active > a,
					.active > a {
						text-decoration: underline;
					}

					> a:hover {
						color: $color-secondary;
					}

					ul {
						display: block;

						a:hover {
							color: $color-secondary;
						}
					}
				}
			}
		}

		&.active > a {
			text-decoration: underline;
		}

		a {
			color: #fff;
			display: block;
			padding: 8px 20px;
			font: 16px/20px $font-title;
			border-bottom: 1px solid rgba(255,255,255,0.2);

			&:hover {
				color: $color-secondary;
				text-shadow: none;
			}

			@media #{$laptop} {
				font-size: 18px;
				padding: 37px 20px 11px;
				text-shadow: 0px 0px 10px rgba(0, 70, 173, 0.5);
				border: none;

				.headroom--pinned.headroom--not-top & {
					color: $color-text;
					text-shadow: none;

					&:hover {
						color: $color-secondary;
					}
				}
			}
		}

		// Second level
		ul {
			margin: 0;
			z-index: 1;

			li a {
				padding: 14px 20px 16px 40px;
				font-size: 14px;
			}

			@media #{$laptop} {
				display: none;
				position: absolute;
				top: 68px;
				left: 0;
				background: #fff;
				min-width: 100%;
				color: $color-text;
				box-shadow: 0px 20px 5px 0px rgba(0,0,0,0.25);

				li {
					margin: 0;
					float: left;
					clear: both;


					a {
						text-shadow: none;
						white-space: nowrap;
						padding: 13px 20px 17px 20px;
						font-size: 18px;

						&:hover {
							color: $color-primary;
						}
					}
				}
			}
		}

		@media #{$laptop} {
			width: auto;
		}
	}
}
