﻿.contact-information {  
	overflow: hidden;
	margin: 0 0 26px;
}

.contact-information__title {
	float: left;
	width: 107px;
	padding-right: 10px;
}

.contact-information__description {
	float: left;
	width: calc(100% - 107px);

	p {
		margin: 0;
	}
}

.contact-information__link {

	footer & {
		color: $color-white;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

.contact-information__link[href^=tel] {
	color: $color-text;
	text-decoration: none;

	footer & {
		color: $color-white;
	}

	&:hover {
			text-decoration: underline;
		}
	} 