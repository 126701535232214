﻿footer {
	background: $color-primary-contrast;
	color: $color-white;
	position: relative;
	z-index: 0;
}

.footer__title {
	color: $color-white;
	font: bold 20px/24px $font-title;
	margin: -3px 0 28px;
}

.footer__text {
	font: 400 16px/24px $font-title;
}

.footer__container {
	padding: 60px 20px 24px;
}

.footer__contact {
	margin: 33px 0 0;
}

.footer__contact-title {
	font: bold 16px/24px $font-title;
}
