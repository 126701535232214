﻿// Aside
aside {
	order: 2;
	width: 100%;
	padding: 0 20px 40px;

	@media #{$tablet} {
		position: relative;
		float: left;
		width: 244px;
		padding: 60px 0 60px 0;
		margin: 0;

		+ .content {
			box-sizing: border-box;
			float: left;
			width: calc(100% - 244px);
			@include clearfix();
			padding-left: 36px;
			order: 1;

			+ * {
				clear: both;
			}

			section {
				.container {
					padding: 0 0 0 40px;
				}
			}
		}
	}

	@media #{$laptop} {
		width: 294px;

		+ .content {
			width: calc(100% - 294px);
		}
	}
}

.aside__ctc {
	background: $color-primary-dark;
	padding: 30px 20px 36px;
	margin: 30px 0 0;
}

.aside__ctc-title {
	color: $color-secondary-light;
	font: 24px/36px $font-title;
}

.aside__ctc-link {
	position: relative;
	display: block;
	color: $color-white;
	font: bold 24px/36px $font-title;
	padding: 0 0 0 37px; 
	margin: -13px 0 0;

	&:before {
		position: absolute;
		@include icon($icon-phone);
		top: 8px;
		left: 1px;
	}
}