﻿.header-image {
	background: $color-secondary-light url(../../images/background-splash.jpg) 50% 0 no-repeat;
	position: relative;
	width: 100%;
	height: 300px;
	padding-top: 100px;
	top: 0;
	left: 0;

	@media #{$laptop} {
		box-sizing: border-box;
		height: 360px;
		padding-top: 100px;
	}

	h1 {
		color: #fff;
		margin: 10px 0 0;

		@media #{$laptop} {
			margin-top: 38px;
		}
	}
}

.header-image--frontpage {
	padding-bottom: 138px;
	background-size: cover;
	height: 380px;
	
	@media #{$tablet} {
		height: 780px;
		padding-bottom: 0;
	}

	@media #{$laptop} {
		height: 780px;
	}
}