﻿/*------------------------------------*\
	#Google maps
\*------------------------------------*/

.google-maps__wrapper {
	position: relative;
	background: $color-contrast;

	&:before {
		box-sizing: border-box;
		position: absolute;
		content: "";
		width: 1280px;
		height: 100%;
		top: 1px;
		left: 100%;
		background: $color-contrast;
		border-bottom: 1px solid $color-border;
	}
}

.google-maps {
	width: 100%;
	min-height: 480px;
	top: 0;
	right: 0;

	.canvas {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		.gmnoprint,
		.gm-style-cc {
			opacity: 0;
			transition: opacity 0.5s;
		}

		&:hover .gmnoprint,
		&:hover .gm-style-cc {
			opacity: 1;
		}
	}

	img {
		visibility: visible;
		width: auto;
	}

	.google-maps__static {
		display: block;
		width: auto;
		height: 100%;
		top: 0;
		left: 0;
	}
}