﻿/*------------------------------------*\
	#Responsive nav
\*------------------------------------*/

.responsive-nav {
    background: rgba(0, 39, 118, 0.9);
    box-sizing: border-box;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100vh;
    padding: 40px 0 80px;
    top: 0;
    left: 0;
    transition: left 0.3s ease-in-out;
    overflow-x: hidden;
    overflow-y: visible;

    &.collapsed {
        left: -100%;
    }

    @media #{$laptop} {
        position: static;
        width: auto;
        height: auto;
        padding-top: 0;
        opacity: 1;
        background: none;
        overflow: visible;
        transition: none;
        box-shadow: none;

        &.collapsed {
            left: 0;
            transition: none;
        }
    }
}