﻿.form {
	padding: 7px 0 0;
	margin: 0 0 41px;

	input[type=text],
	textarea,
	select,
	.form__radio-button-list {
		float: left;
		width: 100%;
	}

	.form__control {
		overflow: hidden;
		margin: 0 0 10px;
	}

	.form__text-area {
		overflow: hidden;
		margin: 0 0 12px;
	}

	.form__control--button {
		text-align: right;
	}

	.input-validation-error {
		background: mix(red, #fff, 20%) !important;
		border: 1px solid mix(red, #fff, 40%);
	}

	fieldset:last-of-type {
		display: none;
	}
}
