﻿.quote-bar__content {
	position: relative;
	font: italic 400 18px/24px $font-title;
	text-align: center;
	color: $color-secondary;
	max-width: 820px;
	margin: 0 auto;

	strong {
		color: $color-primary;
	}

	p {
		margin: 0 0 19px;
	}

	&:before,
	&:after {
		position: absolute;
		font-size: 24px;
		color: $color-secondary-light;
	}

	&:before {
		@include icon($icon-quote-left);
		top: -2px;
		left: -93px;
	}

	&:after {
		@include icon($icon-quote-right);
		right: -74px;
		bottom: -9px;
	}
}
