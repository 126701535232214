﻿.text ul,
.listing {
	overflow: hidden;
	//border-bottom: 1px solid $color-border;
	list-style: none;
	padding: 1px 0 0;

	li {
		position: relative;
		border-top: 1px solid $color-border;
		border-bottom: 1px solid $color-border;
		padding: 13px 20px 13px 48px;
		background: $color-white;

		a {
			position: relative;
			display: block;
			padding: 13px 20px 13px 48px;
			margin: -13px -20px -13px -48px;

			&:before {
				position: absolute;
				@include icon($icon-arrow-right);
				top: 21px;
				left: 21px;
				font-size: 10px;
			}
		}

		&:before {
			position: absolute;
			@include icon($icon-arrow-right);
			top: 21px;
			left: 21px;
			font-size: 10px;
		}

		&:nth-child(odd) {
			background: $color-contrast;
		}

		+ li {
			margin-top: -1px;
		}
	}
}

.listing--aside li {

	a,
	&:before {
		color: $color-secondary;
	}
}

.listig--footer {
	border: none;

	li {
		border: none;
		color: $color-white;


		&:nth-child(odd) {
			background: #193a7d;
		}

		&:nth-child(even) {
			background: $color-primary-contrast;
		}
	}

	a {
		color: $color-white;
	}
}

.listing--columns {

	@media #{$tablet} {
		column-count: 2;
		column-gap: 40px;
	}
}